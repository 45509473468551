export default [
  {
    groupName: '10',
    options: [
      {
        value: '10-for-20',
        iconPrefix: 'fas',
        icon: 'glass-martini-alt',
      },
      {
        value: '10-for-25',
        iconPrefix: 'fas',
        icon: 'utensils',
      },
      {
        value: '10-for-30',
        iconPrefix: 'fas',
        icon: 'ticket-alt',
      },
      {
        value: '10-for-50',
        iconPrefix: 'fas',
        icon: 'ticket-alt',
      },
    ],
  },
  {
    groupName: '20',
    options: [
      {
        value: '20-for-40',
        iconPrefix: 'fas',
        icon: 'glass-martini-alt',
      },
      {
        value: '20-for-50',
        iconPrefix: 'fas',
        icon: 'utensils',
      },
      {
        value: '20-for-60',
        iconPrefix: 'fas',
        icon: 'ticket-alt',
      },
    ],
  },
];
