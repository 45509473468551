import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  isChecked: computed('blackoutDays', 'day', function() {
    // check the toggle if it's enabled (aka not in blackout days)
    return this.blackoutDays.indexOf(this.day) < 0;
  }),

  actions: {
    toggleWeekday(checked) {
      const index = this.blackoutDays.indexOf(this.day);

      if (checked) {
        console.log('removing', this.day);
        this.blackoutDays.splice(index, 1);
      } else {
        if (index < 0) {
          console.log('adding', this.day);
          this.blackoutDays.push(this.day)
        }
      }

      this.setProperty('blackoutDays', this.blackoutDays);
    },
  }
});
